// extracted by mini-css-extract-plugin
export var base = "MarketingMobileAppPage-module--base--35pNv";
export var rightAlignedContainer = "MarketingMobileAppPage-module--rightAlignedContainer--DoVe4";
export var rightAlignedContainerSmall = "MarketingMobileAppPage-module--rightAlignedContainerSmall--3vJeZ";
export var leftAlignedContainer = "MarketingMobileAppPage-module--leftAlignedContainer--lwgeI";
export var blogContainer = "MarketingMobileAppPage-module--blogContainer--2K2nA MarketingMobileAppPage-module--base--35pNv";
export var container = "MarketingMobileAppPage-module--container--cImps MarketingMobileAppPage-module--base--35pNv";
export var largeContainer = "MarketingMobileAppPage-module--largeContainer--27G-8 MarketingMobileAppPage-module--base--35pNv";
export var mobileSmallPadding = "MarketingMobileAppPage-module--mobileSmallPadding--3-jVU";
export var fullWidthMobile = "MarketingMobileAppPage-module--fullWidthMobile--1nRAP";
export var gridWithSidebar = "MarketingMobileAppPage-module--gridWithSidebar--2G-db";
export var noMaxWidth = "MarketingMobileAppPage-module--noMaxWidth--2lidP";
export var community = "MarketingMobileAppPage-module--community--3uiR9";
export var copyLockupList = "MarketingMobileAppPage-module--copyLockupList--2jb8b";
export var iconListWithMedia = "MarketingMobileAppPage-module--iconListWithMedia--2hQdx";
export var hero = "MarketingMobileAppPage-module--hero--RS521";
export var pitch = "MarketingMobileAppPage-module--pitch--18ksL";