import '../../utils/marketingPageDataFragment';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CommunityModule, { CommunityDataPropsObject } from '../../components/CommunityModule';
import CopyLockupListModule, { CopyLockupListDataPropsObject } from '../../components/CopyLockupListModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import IconListWithMediaModule, { IconListWithMediaDataPropsObject } from '../../components/IconListWithMediaModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import setHeroVideoSchemaInfo from '../../utils/setHeroVideoSchema';
import {
  community as communityClass,
  copyLockupList as copyLockupListClass,
  hero as heroClass,
  iconListWithMedia as iconListWithMediaClass,
  pitch as pitchClass
} from './MarketingMobileAppPage.module.css';

export default function MarketingMobileAppPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    noIndex,
    showSiteNav,
    slug,
    title
  } = page;
  const SEOInfo = {
    title,
    description   : description && description.description,
    slug,
    canonicalSlug : canonicalUrlPage && canonicalUrlPage.slug,
    noIndex
  };

  const {
    community,
    copyLockupList,
    hero,
    iconListWithMedia,
    pitch
  } = componentsQueryResultToData(components);

  if (imageFieldName && imageFieldName.file && imageFieldName.file.url) {
    SEOInfo.image = imageFieldName.file.url;
  }

  SEOInfo.heroVideoSchemaInfo = setHeroVideoSchemaInfo(hero);

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={heroClass} data={hero} />
      <IconListWithMediaModule align="left" className={iconListWithMediaClass} data={iconListWithMedia[0]} />
      <IconListWithMediaModule align="right" className={iconListWithMediaClass} data={iconListWithMedia[1]} />
      <CopyLockupListModule childrenLayout="centered" className={copyLockupListClass} data={copyLockupList} />
      <PitchModule className={pitchClass} data={pitch} />
      <CommunityModule className={communityClass} data={community} />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingMobileAppPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingMobileAppPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCommunity']),
          ...CommunityDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyLockupList']),
          ...CopyLockupListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentIconListWithMedia']),
          ...IconListWithMediaDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
